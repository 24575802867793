import "./style.scss";

import { graphql } from "gatsby";

import WithSidebarPage from "../../../layouts/WithSideBarPage";
import ToolkitLink from "../../../components/Sidebar/ToolkitLink";
import BreadcrumbsBlock from "../../../components/Breadcrumbs/BreadcrumbsBlock";

import { createClassName } from "../../../helpers";
import { imageManager } from "../../../components/Image";
import {
  note,
  ul,
  ol,
  li,
  previewList,
  outsideLink,
  arrowIcon,
  marker,
  paragraph,
  heading,
  supportMail,
  renderCom,
} from "../../../components/ForMarkDownComponents";

const cn = createClassName("getStarted");

const subTitle = ({ children, level = 5, fontLevel = 5 }) => heading({ children, level, fontLevel });

const docHeading = ({ children, id, sidebar }) => (
  <div id={sidebar || id} className={cn("heading", { section: true })}>
    {heading({ children, id })}
  </div>
);

const markList = ({ children, bottomIndent = true }) => previewList({ children, bottomIndent });
const previewTitle = ({
 children, id, sidebar, level = 1, fontLevel = 3, weight = "normal",
}) => (
  <div id={sidebar || id} className={cn("heading", { section: true })}>
    {heading({
 children, id, level, fontLevel, weight,
})}
  </div>
);

const renderComToolkit = {
  ul,
  ol,
  li,
  title: docHeading,
  h5: subTitle,
  p: paragraph,
  arrow: arrowIcon,
  note,
  mark: marker,
  "outside-link": outsideLink,
  "preview-list": markList,
  "support-mail": supportMail,
  "preview-title": previewTitle,
};

const GetStarted = ({ data }) => {
  const { htmlAst, frontmatter } = data.markdownRemark;
  const supportDocRenderCom = renderCom({
    ...renderComToolkit,
    img: imageManager(data.pageImages),
  });

  const sidebarSubTitle = frontmatter.featureTitleToolkit ? frontmatter.featureTitleToolkit : t("sidebar.featureTitleToolkit");

  return (
    <WithSidebarPage
      seo={frontmatter.seo[0]}
      breadcrums={<BreadcrumbsBlock frontmatter={frontmatter} />}
      sidebarChildrenTitle={frontmatter.sidebarChildrenTitle}
      headingsClassName=".getStarted__heading"
      sidebarChildrenBefore={(
        <ToolkitLink
          sidebarSubTitle={sidebarSubTitle}
          href={frontmatter.href}
          featureToolkit={frontmatter.featureToolkit}
        />
      )}
      className={cn({ wrap: true })}
    >
      {supportDocRenderCom(htmlAst)}
    </WithSidebarPage>
  );
};

export default GetStarted;

export const query = graphql`
  query getGetStarted($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        path
        bannerTitle
        bannerSubTitle
        sidebarChildrenTitle
        featureTitleToolkit
        featureToolkit
        href
      }
    }
    pageImages:allFile(
      filter: {
        relativePath: {
          regex: "/^images/xltools-ribbon-overview/"
        }
      }
    )
    {
      nodes {
        ...stdFluidImage
      }
    }
  }
`;
